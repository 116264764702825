<template>
  <v-container v-if="!loadView" fluid class="pt-0 px-0">
    <!-- Pago a proveedores -->
    <v-form v-model="isFormValid" ref="filters-form" id="filters-form">
      <v-row dense>
        <v-col cols="1" md="1" class="px-0 pt-2 pb-2">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="24"
                v-on="on"
                @click="volver()"
                tabindex="-1"
              >
                {{ volverIcon }}
              </v-icon>
            </template>
            <span>Atrás</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11" md="8" class="pb-0 pt-0 px-0" tabindex="-1">
          <PageHeader :title="title" class="pt-0 pb-0" />
        </v-col>
        <v-col
          cols="12"
          class="p-0 pb-0"
          v-if="isEditSoloImputacion || !isEditTotalOp"
          tabindex="0"
        >
          <span class="error--text" style="font-size: 15px">{{
            mensajeEditSoloImputacionNc
          }}</span>
        </v-col>
        <v-col cols="12" class="p-0 pb-2">
          <v-card outlined>
            <v-col cols="12" class="pt-0 pb-0 text-right" align="end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="primary--text text-right"
                    @click="showHeader = !showHeader"
                  >
                    {{ !showHeader ? chevronDownIcon : chevronUpIcon }}
                  </v-icon>
                </template>
                <span>{{
                  !showHeader ? "Mostrar cabecera" : "Ocultar cabecera"
                }}</span>
              </v-tooltip>
            </v-col>
            <v-card-text class="py-1 px-5">
              <v-expand-transition>
                <div v-if="showHeader">
                  <v-row dense>
                    <v-col class="p-0 pb-1" cols="12" md="4">
                      <v-autocomplete
                        v-model="empresaSelected"
                        return-object
                        :items="empresas"
                        :disabled="
                          !isEditTotalOp ||
                          (tipoComprobanteSelected !== undefined &&
                            tipoComprobanteSelected !== null)
                        "
                        :filled="
                          !isEditTotalOp ||
                          (tipoComprobanteSelected !== undefined &&
                            tipoComprobanteSelected !== null)
                        "
                        outlined
                        item-value="id"
                        @input="getTiposComprobantes()"
                        :rules="rules.required"
                        tabindex="1"
                        item-text="value"
                        clearable
                        ref="btnComboEmpresas"
                        id="btnComboEmpresas"
                        hide-details="auto"
                        dense
                        label="Empresa"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" class="p-0 pl-1 pb-1">
                      <v-text-field
                        type="text"
                        outlined
                        tabindex="2"
                        :disabled="!isEditTotalOp"
                        :filled="!isEditTotalOp"
                        dense
                        hide-details="auto"
                        ref="codigoProveedor"
                        id="codigoProveedor"
                        label="Código proveedor"
                        clearable
                        v-model.trim="provCod"
                        @focus="bloqueaOnFocus = true"
                        @blur="bloqueaOnFocus = false"
                        :rules="
                          rules.required.concat([rules.maxLength(provCod, 10)])
                        "
                        @change="buscarProveedor()"
                        autocomplete="off"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pr-4">
                      <v-row>
                        <v-col cols="12" md="1" class="pt-2 pl-4 pr-0">
                          <v-tooltip top max-width="50%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                class="px-0"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!isEditTotalOp"
                                :filled="!isEditTotalOp"
                                tabindex="-1"
                                @click="toggleModalBusquedaProv"
                              >
                                {{ searchIcon }}
                              </v-icon>
                            </template>
                            <span
                              >Buscar proveedor por nombre, CUIT o código</span
                            >
                          </v-tooltip>
                        </v-col>
                        <!-- DATOS PROVEEDOR -->

                        <v-col cols="12" md="11" class="p-0 pb-1">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            disabled
                            :rules="rules.required"
                            label="Proveedor"
                            hide-details=""
                            :loading="proveedoresLoading"
                            v-model.trim="provNom"
                            autocomplete="off"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="3" class="p-0">
                      <v-autocomplete
                        v-model="condicionGananciaSelected"
                        :items="condicionesGanancias"
                        dense
                        outlined
                        return-object
                        hide-details="auto"
                        label="Cond. ganancia"
                        disabled
                        tabindex="3"
                        filled
                        item-value="id"
                        item-text="value"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" class="p-0 pl-1">
                      <v-autocomplete
                        v-model="conceptoGananciaSelected"
                        :items="conceptosGanancias"
                        dense
                        outlined
                        return-object
                        hide-details="auto"
                        label="Concepto Ganancia"
                        tabindex="4"
                        clearable
                        :disabled="
                          retencionesItems.length > 0 || !isEditTotalOp
                        "
                        :filled="retencionesItems.length > 0 || !isEditTotalOp"
                        item-value="id"
                        item-text="value"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" class="p-0 pl-1">
                      <v-autocomplete
                        v-model="condicionIBSelected"
                        :items="condicionesIB"
                        dense
                        outlined
                        return-object
                        hide-details="auto"
                        tabindex="5"
                        label="Cond. IIBB"
                        disabled
                        filled
                        item-value="id"
                        item-text="value"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" class="p-0 pl-1">
                      <v-autocomplete
                        v-model="conceptoIBSelected"
                        :items="conceptosIB"
                        dense
                        outlined
                        return-object
                        tabindex="6"
                        hide-details="auto"
                        label="Concepto IIBB"
                        :disabled="
                          retencionesItems.length > 0 || !isEditTotalOp
                        "
                        :filled="retencionesItems.length > 0 || !isEditTotalOp"
                        item-value="id"
                        item-text="value"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="pl-4 pr-3">
                      <v-row>
                        <v-col cols="12" md="6" class="p-0 pt-1">
                          <v-autocomplete
                            v-model="tipoComprobanteSelected"
                            :items="tiposComprobantes"
                            ref="tipoComprobante"
                            tabindex="7"
                            outlined
                            return-object
                            clearable
                            :disabled="empresaSelected == null || isEdit"
                            :filled="empresaSelected == null || isEdit"
                            :loading="tiposComprobantesLoading"
                            :rules="rules.required"
                            item-value="id"
                            item-text="value"
                            dense
                            label="Tipo comprobante"
                            hide-details="auto"
                            @change="setDatosTipoComprobante()"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="3" class="p-0 px-4">
                          <v-row>
                            <v-col class="p-0 pl-1 pt-1" md="5">
                              <v-text-field
                                v-model="letraComprobante"
                                :rules="rules.required"
                                v-mask="'A'"
                                dense
                                tabindex="8"
                                outlined
                                hide-details="auto"
                                label="Letra"
                                @change="
                                  getTipoCompNumAutomatico(
                                    tipoComprobanteSelected,
                                    letraComprobante,
                                    sucursalComprobante
                                  )
                                "
                                :filled="tieneNumeradorAutomatico || isEdit"
                                :disabled="tieneNumeradorAutomatico || isEdit"
                              ></v-text-field>
                            </v-col>
                            <v-col class="p-0 pl-1 pt-1" md="7">
                              <v-text-field
                                v-model="sucursalComprobante"
                                :rules="[
                                  rules.decimalGreaterThanZero(
                                    parseInt(sucursalComprobante)
                                  ),
                                ]"
                                @change="
                                  getTipoCompNumAutomatico(
                                    tipoComprobanteSelected,
                                    letraComprobante,
                                    sucursalComprobante
                                  )
                                "
                                v-mask="'#####'"
                                dense
                                tabindex="9"
                                outlined
                                hide-details="auto"
                                label="Sucursal"
                                :filled="tieneNumeradorAutomatico || isEdit"
                                :disabled="tieneNumeradorAutomatico || isEdit"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="p-0 pl-1 pr-1 pt-1" cols="4" md="3">
                          <v-text-field
                            v-model="numeroComprobante"
                            v-mask="'#########'"
                            dense
                            outlined
                            hide-details="auto"
                            tabindex="10"
                            label="Número"
                            :rules="
                              tieneNumeradorAutomatico
                                ? []
                                : [
                                    rules.decimalGreaterThanZero(
                                      numeroComprobante
                                    ),
                                  ]
                            "
                            :filled="tieneNumeradorAutomatico || !isEditTotalOp"
                            :disabled="
                              tieneNumeradorAutomatico || !isEditTotalOp
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col class="px-4" cols="6" md="3">
                      <v-row dense>
                        <v-col cols="6" class="pr-0 pb-0">
                          <v-menu
                            ref="fecha"
                            v-model="menuFecha"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fechaSelected"
                                label="Fecha"
                                hint="Formato DD/MM/AAAA"
                                @blur="
                                  fecha = helpers.parseDateToIso(fechaSelected)
                                "
                                outlined
                                tabindex="11"
                                autocomplete="not"
                                hide-details="auto"
                                dense
                                :filled="!isEditTotalOp"
                                :disabled="!isEditTotalOp"
                                @change="
                                  monedaOPSelected && showCotizacion
                                    ? getCotizacion(monedaOPSelected.id)
                                    : null
                                "
                                clearable
                                v-mask="'##/##/####'"
                                v-bind="attrs"
                                v-on="on"
                                :rules="rules.required.concat(rules.validDate)"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fecha"
                              no-title
                              @change="
                                (fechaSelected = helpers.formatDate(fecha)),
                                  monedaOPSelected && showCotizacion
                                    ? getCotizacion(monedaOPSelected.id)
                                    : null
                              "
                              @input="menuFecha = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" class="pr-0 pb-0">
                          <v-menu
                            :disabled="disableFecContable"
                            :filled="disableFecContable"
                            ref="fechaContable"
                            v-model="menuFechaContable"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fechaContableSelected"
                                label="Fecha contable"
                                hint="Formato DD/MM/AAAA"
                                @blur="
                                  fechaContable = helpers.parseDateToIso(
                                    fechaContableSelected
                                  )
                                "
                                :filled="!isEditTotalOp"
                                :disabled="!isEditTotalOp"
                                tabindex="12"
                                outlined
                                autocomplete="not"
                                hide-details="auto"
                                dense
                                clearable
                                v-mask="'##/##/####'"
                                v-bind="attrs"
                                v-on="on"
                                :rules="rules.required.concat(rules.validDate)"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fechaContable"
                              no-title
                              @change="
                                fechaContableSelected =
                                  helpers.formatDate(fechaContable)
                              "
                              @input="menuFechaContable = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- Concepto del pago -->
                    <v-col cols="12" md="3" class="pt-2 pr-0">
                      <v-text-field
                        v-model="conceptoPago"
                        dense
                        :disabled="!isEditTotalOp"
                        :filled="!isEditTotalOp"
                        tabindex="13"
                        outlined
                        :rules="[rules.maxLength(conceptoPago, 100)]"
                        hide-details="auto"
                        label="Concepto del pago"
                      ></v-text-field>
                    </v-col>
                    <v-row dense>
                      <v-col cols="12" md="3" class="pr-n2 pl-4 pb-0 pt-1">
                        <v-text-field
                          dense
                          disabled
                          filled
                          outlined
                          v-model="modalidadPago"
                          hide-details="auto"
                          label="Modalidad de pago"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="pr-0 pl-0 pb-0 pt-1">
                        <v-text-field
                          dense
                          disabled
                          filled
                          outlined
                          v-model="tipoValor"
                          hide-details="auto"
                          label="Tipo de valor"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2" class="p-0 pt-1 pl-1">
                        <v-autocomplete
                          v-if="parametroGeneralMultimoneda"
                          v-model="monedaProvSelected"
                          :items="monedas"
                          return-object
                          disabled
                          filled
                          item-value="id"
                          item-text="value"
                          outlined
                          tabindex="14"
                          :rules="rules.required"
                          clearable
                          dense
                          label="Moneda proveedor"
                          hide-details="auto"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="2" class="p-0 pt-1 pl-1">
                        <v-autocomplete
                          v-model="monedaOPSelected"
                          :items="monedas"
                          @change="
                            monedaOPSelected
                              ? getCotizacion(monedaOPSelected.id)
                              : null
                          "
                          return-object
                          :disabled="
                            retencionesItems.length > 0 ||
                            comprobantes.length > 0 ||
                            !isEditTotalOp
                          "
                          :filled="
                            retencionesItems.length > 0 ||
                            comprobantes.length > 0 ||
                            !isEditTotalOp
                          "
                          v-if="parametroGeneralMultimoneda"
                          item-value="id"
                          item-text="value"
                          outlined
                          tabindex="15"
                          :rules="rules.required"
                          clearable
                          dense
                          label="Moneda OP"
                          hide-details="auto"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="2" class="pt-1 pr-4 pb-0">
                        <currency-input
                          v-if="showCotizacion && parametroGeneralMultimoneda"
                          dense
                          :loading="cotizacionLoading"
                          tabindex="16"
                          v-model="cotizacion"
                          outlined
                          :disabled="
                            comprobantes.length > 0 ||
                            retencionesItems.length > 0 ||
                            !isEditTotalOp
                          "
                          :filled="
                            comprobantes.length > 0 ||
                            retencionesItems.length > 0 ||
                            !isEditTotalOp
                          "
                          :rules="[]"
                          :options="currencyOptions"
                          hide-details="auto"
                          label="Cotización"
                        ></currency-input>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Comprobantes a cancelar -->
      <v-row dense>
        <v-col cols="12" class="p-0">
          <v-card outlined class="pb-3">
            <v-row>
              <v-col cols="12" md="6" class="pt-2 pb-0 pl-6">
                <span class="primary--text font-weight-medium py-0 pl-2"
                  >Comprobantes a cancelar</span
                >
              </v-col>
            </v-row>
            <v-card-text class="py-1">
              <v-data-table
                :headers="filteredHeadersComp"
                :items="comprobantes"
                :search="search"
                dense
                no-data-text="No hay comprobantes a cancelar"
                :hide-default-footer="comprobantes.length <= 10"
                class="elevation-1 custom-data-table"
              >
                <template v-slot:top>
                  <v-row dense>
                    <v-col cols="12" md="6" class="py-0 pl-6">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        dense
                        outlied
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2 pt-1 text-right">
                      <v-btn
                        small
                        class="px-4 mx-2"
                        outlined
                        :disabled="
                          comprobantes.length == 0 ||
                          (!isEditTotalOp && !isEditSoloImputacion) ||
                          comprobantes.some(
                            (x) => x.tieneDifCambioConAsientoCont
                          )
                        "
                        color="primary"
                        @click="comprobantes = []"
                        >Quitar todos</v-btn
                      >
                      <v-btn
                        @click="exportComprobantes()"
                        small
                        class="px-4 mx-2"
                        outlined
                        :disabled="comprobantes.length === 0"
                        color="primary"
                        >Exportar</v-btn
                      >
                      <v-btn
                        small
                        tabindex="17"
                        class="pl-4 ml-2 mr-5"
                        color="primary"
                        @click="
                          () => {
                            //indicamos que comprobantes se van a excluir de la busqueda
                            if (comprobantes.length > 0) {
                              idsComprobantesExcluir = comprobantes.map(
                                (comprobante) => comprobante.mProvId
                              );
                            } else idsComprobantesExcluir = [];
                            buscarComprobantesCancelar = true;
                          }
                        "
                        :disabled="
                          bloqueaOnFocus ||
                          (showCotizacion && !cotizacion) ||
                          proveedorSelected == null ||
                          empresaSelected == null ||
                          !tipoComprobanteSelected ||
                          (!isEditTotalOp && !isEditSoloImputacion)
                        "
                        >Agregar comprobantes</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      class="p-0 pl-6"
                      v-if="
                        comprobantes.some((x) => x.tieneDifCambioConAsientoCont)
                      "
                      tabindex="0"
                    >
                      <span class="error--text"
                        >Hay imputaciones que no pueden ser modificadas por
                        tener comprobantes de dif. de cambio generados con
                        asiento contable.</span
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <span>{{
                    helpers.floatToMoneyString(item.total, "", true)
                  }}</span>
                </template>
                <template v-slot:[`item.saldo`]="{ item }">
                  <span>{{
                    helpers.floatToMoneyString(item.saldo, "", false)
                  }}</span>
                </template>
                <template v-slot:[`item.saldoComp`]="{ item }">
                  <span>{{
                    helpers.floatToMoneyString(item.saldoComp, "", false)
                  }}</span>
                </template>
                <template v-slot:[`item.cancelado`]="{ item }">
                  <span>{{
                    helpers.floatToMoneyString(item.saldo, "", true)
                  }}</span>
                </template>
                <template v-slot:[`item.cancela`]="{ item }">
                  <currency-input
                    v-if="!monedaProvDistintaDefecto"
                    v-model="item.cancela"
                    dense
                    hide-details="auto"
                    class="to-right right-input"
                    outlined
                    :disabled="!isEditTotalOp && !isEditSoloImputacion"
                    :filled="!isEditTotalOp && !isEditSoloImputacion"
                    style="
                      max-width: 140px;
                      font-size: 12px;
                      width: 140px;
                      display: inline-block;
                    "
                    :rules="[
                      rules.decimalEqualOrLess(item.cancela, item.saldo),
                      rules.decimalGreaterThanZero(item.cancela),
                    ]"
                    @change="onChangeCancela(item)"
                    :options="currencyOptions"
                  ></currency-input>
                  <currency-input
                    v-else
                    v-model="item.cancelaComp"
                    dense
                    hide-details="auto"
                    class="to-right right-input"
                    outlined
                    :disabled="
                      (!isEditTotalOp && !isEditSoloImputacion) ||
                      item.tieneDifCambioConAsientoCont
                    "
                    :filled="
                      (!isEditTotalOp && !isEditSoloImputacion) ||
                      item.tieneDifCambioConAsientoCont
                    "
                    style="
                      max-width: 140px;
                      font-size: 12px;
                      width: 140px;
                      display: inline-block;
                    "
                    :rules="[
                      rules.decimalEqualOrLess(
                        item.cancelaComp,
                        item.saldoComp
                      ),
                      rules.decimalGreaterThanZero(item.cancelaComp),
                    ]"
                    @change="onChangeCancelaComp(item)"
                    :options="currencyOptions"
                  ></currency-input>
                </template>
                <template v-slot:[`item.cotizacionComprobante`]="{ item }">
                  <span>{{
                    helpers.floatToMoneyString(
                      item.cotizacionComprobante,
                      "",
                      true
                    )
                  }}</span>
                </template>
                <template v-slot:[`item.diferenciaCambio`]="{ item }">
                  <v-tooltip top max-width="20rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs"
                        >{{
                          helpers.floatToMoneyString(
                            item.diferenciaCambio,
                            siglaMonedaPorDefecto,
                            false
                          )
                        }}
                        <v-icon class="mt-0" small>
                          {{ infoIcon }}
                        </v-icon></span
                      >
                    </template>
                    <v-row>
                      <v-col cols="12" class="p-0">
                        <span>
                          (Cancela * Cotiz. O.P) - (Cancela * Cotiz. Fact.)
                        </span>
                      </v-col>
                      <v-col cols="12" class="p-0">
                        <span>
                          {{
                            `${helpers.floatToMoneyString(
                              monedaProvSelected == monedaPorDefecto
                                ? item.cancela
                                : item.cancelaComp,
                              "",
                              true
                            )} * ${helpers.floatToMoneyString(
                              parseFloat(cotizacion),
                              "",
                              true
                            )} - ${helpers.floatToMoneyString(
                              monedaProvSelected == monedaPorDefecto
                                ? item.cancela
                                : item.cancelaComp,
                              "",
                              true
                            )} * ${helpers.floatToMoneyString(
                              item.cotizacionComprobante,
                              "",
                              true
                            )} = ${helpers.floatToMoneyString(
                              item.diferenciaCambio,
                              "",
                              true
                            )}`
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="goToVerComprobante(item)"
                      >
                        {{ seeIcon }}
                      </v-icon>
                    </template>
                    <span>Ver comprobante</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        :disabled="
                          (!isEditTotalOp && !isEditSoloImputacion) ||
                          item.tieneDifCambioConAsientoCont
                        "
                        @click="
                          comprobantes = comprobantes.filter(
                            (x) => x.mProvId !== item.mProvId
                          )
                        "
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense class="pb-2 pt-2 d-flex justify-space-between">
        <v-col cols="12" class="p-0">
          <v-card class="pb-3 elevation-2 pt-3">
            <v-row no-gutters class="d-flex align-center">
              <!-- Neto -->
              <v-col cols="2" class="pl-4">
                <currency-input
                  v-if="comprobantes.length > 0"
                  label="Neto"
                  v-model="totalNetoComprobantes"
                  :readonly="comprobantes.length > 0"
                  :disabled="comprobantes.length > 0"
                  :filled="comprobantes.length > 0"
                  dense
                  outlined
                  :rules="[]"
                  :options="currencyOptions"
                  :hideDetails="'true'"
                ></currency-input>
              </v-col>
              <!-- Botón de Pago a Cuenta -->
              <v-col
                cols="10"
                align-self="end"
                class="d-flex justify-end text-right"
              >
                <!-- Pago a cuenta -->
                <v-col cols="2" class="text-right pt-1 pl-0">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        tabindex="18"
                        v-on="on"
                        v-bind="attrs"
                        v-model="pagoACuenta"
                        @click="pagoACuenta = !pagoACuenta"
                        :outlined="pagoACuenta"
                        class="py-5"
                        :disabled="
                          comprobantes.length > 0 || !tipoComprobanteSelected
                        "
                        :color="pagoACuenta ? 'gray' : 'primary'"
                        >Pago a cuenta</v-btn
                      >
                    </template>
                    <span> Habilita el pago a cuenta </span>
                  </v-tooltip>
                </v-col>
                <!-- Total en moneda extranjera -->
                <v-col
                  v-if="parametroGeneralMultimoneda && showCotizacion"
                  cols="3"
                  class="py-1 pr-1 pl-12"
                >
                  <currency-input
                    tabindex="19"
                    :label="`Total (${monedaExtranjeraNom})`"
                    v-model="totalEnMonedaExtranjera"
                    :disabled="
                      (comprobantes.length == 0 && !pagoACuenta) ||
                      !isEditTotalOp
                    "
                    :filled="
                      (comprobantes.length == 0 && !pagoACuenta) ||
                      !isEditTotalOp
                    "
                    :hideDetails="'true'"
                    dense
                    @blur="calcularTotalEnPeso()"
                    :rules="[]"
                    :options="currencyOptions"
                    outlined
                  ></currency-input>
                </v-col>
                <!-- Total en moneda por defecto -->
                <v-col cols="2" class="p-0 pr-1 py-1 pr-0 mr-n2">
                  <currency-input
                    tabindex="20"
                    :label="`Total (${monedaPorDefectoNom})`"
                    v-model="totalEnMonedaPorDefecto"
                    :disabled="
                      (comprobantes.length == 0 && !pagoACuenta) ||
                      !isEditTotalOp
                    "
                    :filled="
                      (comprobantes.length == 0 && !pagoACuenta) ||
                      !isEditTotalOp
                    "
                    dense
                    :rules="[]"
                    :options="currencyOptions"
                    @blur="calcularTotalEnDolar()"
                    outlined
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <!-- Botón de Distribuir -->
                <v-col cols="2" align-self="end" class="pr-4 pt-1 text-right">
                  <v-btn
                    tabindex="21"
                    outlined
                    class="py-5"
                    :disabled="
                      comprobantes.length == 0 ||
 
                      comprobantes.some((x) => x.tieneDifCambioConAsientoCont)
                    "
                    @click="distribuirComprobantes()"
                    color="primary"
                    >Distribuir</v-btn
                  >
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- Retenciones y Valores -->
      <v-row dense>
        <v-col cols="12" class="p-0">
          <v-card outlined>
            <v-row>
              <v-col cols="3" class="pb-2 pl-8">
                <span class="primary--text font-weight-medium"
                  >Retenciones y Valores</span
                >
              </v-col>
              <v-col cols="7" class="pt-2 pb-2 ml-13 text-right" align="end">
                <v-btn
                  tabindex="22"
                  @click="setRetenciones()"
                  small
                  :disabled="
                    bloqueaOnFocus ||
                    (showCotizacion && !cotizacion) ||
                    empresaSelected == null ||
                    proveedorSelected == null ||
                    !isEditTotalOp ||
                    (!isEditTotalOp && !isEditSoloImputacion)
                  "
                  class="mr-1"
                  :loading="loadingBtnReteneciones"
                  color="primary"
                  >Calcular retenciones</v-btn
                >
                <v-btn
                  tabindex="23"
                  small
                  :disabled="
                    disabledValores ||
                    bloqueaOnFocus ||
                    !isEditTotalOp ||
                    (!isEditTotalOp && !isEditSoloImputacion)
                  "
                  @click="
                    () => {
                      //indicamos que valores se van a excluir de la busqueda
                      if (retencionesItems.length > 0) {
                        idsValoresExcluir = retencionesItems.map(
                          (val) => val.tvId
                        );
                      } else idsValoresExcluir = [];
                      toggleModalValoresCartera();
                    }
                  "
                  color="primary"
                  >Valores en cartera</v-btn
                >
              </v-col>
              <v-col cols="2" class="pt-2 pb-2 ml-n16 text-right" align="end">
                <v-btn
                  tabindex="24"
                  small
                  ref="btnToggleNuevoTipoValor"
                  @click="toggleNuevoTipoValor"
                  :disabled="
                    disabledValores ||
                    bloqueaOnFocus ||
                    !isEditTotalOp ||
                    (!isEditTotalOp && !isEditSoloImputacion)
                  "
                  color="primary"
                >
                  Nuevo valor
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text class="py-1">
              <v-data-table
                :headers="filteredHeadersRet"
                :items="retencionesItems"
                class="elevation-1"
                no-data-text="No hay retenciones y valores"
                dense
                :hide-default-footer="retencionesItems.length <= 10"
              >
                <template v-slot:[`item.imp`]="{ item }">
                  <span>
                    {{ helpers.floatToMoneyString(item.imp, "", true) }}
                  </span>
                </template>
                <template v-slot:[`item.importeComp`]="{ item }">
                  <span>
                    {{
                      helpers.floatToMoneyString(
                        parseFloat(item.importeComp),
                        "",
                        true
                      )
                    }}
                  </span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        :disabled="
                          !isEditTotalOp ||
                          (!isEditTotalOp && !isEditSoloImputacion)
                        "
                        v-on="on"
                        @click="
                          retencionesItems = retencionesItems.filter(
                            (x) => x !== item
                          )
                        "
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6"></v-col>
              <v-col cols="3" align="end" class="text-right pr-2 p-0">
                <currency-input
                  v-if="parametroGeneralMultimoneda && showCotizacion"
                  :label="`Total (${monedaExtranjeraNom})`"
                  v-model="totalValoresMonedaExtranjera"
                  readonly
                  filled
                  disabled
                  dense
                  outlined
                  :rules="[]"
                  :options="currencyOptions"
                  :hideDetails="'true'"
                ></currency-input>
              </v-col>
              <v-col cols=" 3" align="end" class="text-right pr-2 p-0">
                <currency-input
                  :label="`Total (${monedaPorDefectoNom})`"
                  v-model="totalValores"
                  readonly
                  disabled
                  filled
                  dense
                  outlined
                  :rules="[]"
                  :options="currencyOptions"
                  :hideDetails="'true'"
                ></currency-input>
              </v-col>
            </v-card-actions>
          </v-card>
          <v-row cols="12" class="pt-3 to-right" align="end">
            <v-btn
              form="filters-form"
              class="mr-4"
              :loading="loadingSaveBtn"
              :disabled="!isFormValid || totalEnMonedaPorDefecto == 0"
              color="primary"
              @click="saveOrdenPago()"
              >Grabar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <!-- MODAL PROPONER NETO -->
    <v-dialog
      v-model="openInputNetoDialog"
      v-if="openInputNetoDialog"
      @keydown.esc="openInputNetoDialog = false"
      max-width="38%"
      persistent
    >
      <v-card class="py-0">
        <v-card-title class="headline primary--text pl-6 pb-5">
          Ingrese un neto para calcular retenciones</v-card-title
        >
        <v-card-text class="text-left">
          <v-row>
            <v-col cols="6" class="pr-1 py-1">
              <currency-input
                label="Neto"
                v-model="totalNetoComprobantes"
                dense
                outlined
                :rules="[]"
                :options="currencyOptions"
                :hideDetails="'true'"
              ></currency-input>
            </v-col>
          </v-row>
          <v-col cols="12">
            <p class="ml-n3">
              El neto ingresado se tomara como base para calcular las
              retenciones. La moneda se expresa en {{ monedaPorDefectoNom }}
            </p>
          </v-col>
          <v-row>
            <v-col cols="3"> </v-col>
            <v-col cols="9" class="py-0 pr-4 text-right" align="end">
              <v-btn
                class="mr-5"
                outlined
                color="primary"
                @click="openInputNetoDialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="mr-4 text-right"
                color="primary"
                @click="(openInputNetoDialog = false), confirmSetRetenciones()"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
    <!-- MODAL BUSCAR COMPROBANTES A CANCELAR -->
    <v-dialog
      v-model="buscarComprobantesCancelar"
      v-if="buscarComprobantesCancelar"
      @keydown.esc="toggleModalComprobantesCancelar"
      max-width="100%"
      persistent
    >
      <BusquedaComprobantesCancelar
        :empresa="empresaSelected"
        :proveedor="proveedorSelected"
        :monedaOP="monedaOPSelected"
        :idsComprobantesExcluir="idsComprobantesExcluir"
        :monedaProv="monedaProvSelected"
        :cotizacionActual="
          cotizacion == null || cotizacion == undefined
            ? 1
            : parseFloat(cotizacion)
        "
        :showDiferenciaCambio="
          monedaPorDefecto != monedaProvSelected.id &&
          parametroGeneralMultimoneda
        "
        @toggleModalComprobantesCancelar="toggleModalComprobantesCancelar"
        @setComprobantesCancelar="setComprobantesCancelar"
      ></BusquedaComprobantesCancelar>
    </v-dialog>
    <!-- MODAL BUSCAR VALORES CARTERA -->
    <v-dialog
      v-model="buscarValoresCarteraModal"
      v-if="buscarValoresCarteraModal"
      @keydown.esc="toggleModalValoresCartera"
      max-width="70%"
      persistent
    >
      <BuscarValoresEnCartera
        :empresaSelectedId="empresaSelected.id"
        :idsValoresExcluir="idsValoresExcluir"
        @toggleModalValoresCartera="toggleModalValoresCartera"
        @setValoresSelected="setValoresCartera"
      ></BuscarValoresEnCartera>
    </v-dialog>
    <!-- MODAL NUEVO TIPO DE VALOR -->
    <v-dialog
      v-if="detalleTipoValorModal"
      v-model="detalleTipoValorModal"
      max-width="60%"
      @keydown.esc="toggleNuevoTipoValor"
      persistent
    >
      <NuevoTipoValor
        @toggleNuevoTipoValor="toggleNuevoTipoValor"
        @addTipoDeValor="addTipoDeValor"
        :empresaSelected="empresaSelected"
        :movProvId="movProvId"
        :retencionesItems="retencionesItems"
        :tipoValor="tipoValor"
        :fechaSelected="fechaSelected"
        :cotizacion="parseFloat(cotizacion)"
        :monedaOPSelected="monedaOPSelected"
        :importeSugerido="importeSugerido"
      ></NuevoTipoValor>
    </v-dialog>
    <v-dialog
      v-if="openSeeComp"
      v-model="openSeeComp"
      max-width="80%"
      @keydown.esc="openSeeComp = false"
      persistent
    >
      <DetalleComprobanteProvDialog
        @closeModalDelete="openSeeComp = false"
        :paramsToSeeCompDialog="paramsToSeeComp"
        :isDelete="false"
      ></DetalleComprobanteProvDialog>
    </v-dialog>
    <ConfirmDialog
      :text="'Se eliminarán los valores cargados. ¿Desea continuar?'"
      :openConfirm="openConfirmDialog"
      :submitBtn="'SI'"
      :cancelBtn="'NO'"
      @update:openConfirm="openConfirmDialog = false"
      @onConfirm="confirmSetRetenciones"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import BuscarValoresEnCartera from "@/components/modules/proveedores/ordenesPago/BuscarValoresEnCartera.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import BusquedaComprobantesCancelar from "@/components/modules/proveedores/ordenesPago/BusquedaComprobantesCancelar.vue";
import NuevoTipoValor from "@/components/modules/proveedores/ordenesPago/NuevoTipoValor.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import DetalleComprobanteProvDialog from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProvDialog.vue";

export default {
  name: "NuevaOrdenDePago",
  directives: { mask },
  components: {
    PageHeader,
    Ayuda,
    GoBackBtn,
    BuscarProveedor,
    CurrencyInput,
    ConfirmDialog,
    BuscarValoresEnCartera,
    BusquedaComprobantesCancelar,
    NuevoTipoValor,
    DetalleComprobanteProvDialog,
  },
  data() {
    return {
      enums: enums,
      rules: rules,
      helpers: helpers,
      routeToGo: null,
      searchIcon: enums.icons.SEARCH,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      showHeader: true,
      seeIcon: enums.icons.SEE,
      search: null,
      volverIcon: enums.icons.BACK,
      infoIcon: enums.icons.SNB_INFO,
      deleteIcon: enums.icons.DELETE,
      openConfirmDialog: false,
      isFormValid: false,
      bloqueaOnFocus: false,
      loadingSaveBtn: false,
      isEdit: false,
      isEditTotalOp: true,
      loadView: true,
      // fechas
      fecha: null,
      menuFecha: null,
      fechaSelected: null,
      fechaContable: null,
      menuFechaContable: null,
      fechaContableSelected: null,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 999999999999999,
        },
        precision: 2,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true,
      },
      movProvId: null,
      title: "Pago a proveedores",
      empresas: [],
      empresaSelected: null,
      condicionesGanancias: [],
      conceptosGanancias: [],
      condicionesIB: [],
      conceptosIB: [],
      conceptoPago: "",
      proveedores: [],
      tCompSigno: 1,
      condicionGananciaSelected: null,
      conceptoGananciaSelected: null,
      condicionIBSelected: null,
      conceptoIBSelected: null,
      modalidadPago: null,
      tIvaId: null,
      tipoValor: null,
      provCod: null,
      proveedorSelected: null,
      proveedoresLoading: null,
      provId: null,
      provNom: null,
      importeSugerido: null,
      buscarProveedorModal: false,
      buscarValoresCarteraModal: false,
      buscarComprobantesCancelar: false,
      idsComprobantesExcluir: [],
      idsValoresExcluir: [],
      cuit: null,
      monedaOPSelected: null,
      openInputNetoDialog: false,
      monedaProvSelected: null,
      monedaPorDefecto: null,
      siglaMonedaPorDefecto: null,
      monedaPorDefectoNom: null,
      letraComprobante: "",
      sucursalComprobante: "",
      numeroComprobante: "",
      parametroGeneralMultimoneda: false,
      tipoComprobanteSelected: null,
      tieneNumeradorAutomatico: false,
      tiposComprobantesLoading: false,
      tiposComprobantes: [],
      monedas: [],
      cotizacionLoading: false,
      pagoACuenta: false,
      cotizacion: 1,
      disableFecContable: false,
      totalValores: 0,
      totalNetoComprobantes: 0.0,
      totalIvaComprobantes: 0.0,
      totalPercepIvaComprobantes: 0.0,
      totalPercepcionIbComprobantes: 0.0,
      totalImpuestosInternosComprobantes: 0.0,
      totalOtrosConceptosComprobantes: 0.0,
      //totales comp
      totalNetoComprobantesComp: 0.0,
      totalIvaComprobantesComp: 0.0,
      totalPercepIvaComprobantesComp: 0.0,
      totalPercepcionIbComprobantesComp: 0.0,
      totalImpuestosInternosComprobantesComp: 0.0,
      totalOtrosConceptosComprobantesComp: 0.0,
      totalEnMonedaPorDefecto: 0.0,
      totalEnMonedaExtranjera: 0.0,
      totalValoresMonedaExtranjera: 0,
      loadingBtnReteneciones: false,
      detalleTipoValorModal: false,
      tCompEditaFecha: true,
      enableValores: false,
      openSeeComp: false,
      paramsToSeeComp: {},
      mensajeEditSoloImputacionNc: "",
      headersComprobantes: [
        { text: "Comprobante", value: "nroComp", sortable: false },
        { text: "Fecha", value: "fechaComprobanteFormatted", sortable: false },
        {
          text: "Vencimiento",
          value: "fechaVencimientoFormatted",
          sortable: false,
        },
        {
          text: "Total",
          align: "end",
          value: "totalFormatted",
          sortable: false,
        },
        {
          text: "Cancelado",
          align: "end",
          value: "canceladoFormatted",
          sortable: false,
        },
        {
          text: "Saldo",
          align: "end",
          value: "saldo",
          sortable: false,
        },
        {
          text: "Saldo",
          align: "end",
          value: "saldoComp",
          sortable: false,
        },
        { text: "Cancela", align: "end", value: "cancela", sortable: false },
        {
          text: "Dif. Cambio",
          align: "end",
          value: "diferenciaCambio",
          sortable: false,
        },
        { text: "Acc.", align: "end", value: "actions", sortable: false },
      ],
      comprobantes: [],
      headersRetenciones: [
        { text: "Tipo valor", value: "tvNombre", sortable: false },
        { text: "Banco", sortable: false, value: "bancoNombre" },
        { text: "Cuenta", sortable: false, value: "bctaNombre" },
        { text: "Fecha", sortable: false, value: "fechaString" },
        { text: "Número", sortable: false, value: "numero" },
        { text: "Importe pesos", align: "end", sortable: false, value: "imp" },
        {
          text: "Importe dólar",
          align: "end",
          sortable: false,
          value: "importeComp",
        },
        { text: "Eliminar", align: "end", sortable: false, value: "actions" },
      ],
      retencionesItems: [],
      isEditSoloImputacion: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    // Valido que si no es el componente NuevaOrdenPago, borre los filtros
    if (to.path.includes("/proveedores/carga-orden-pago")) next();
    else if (to.path.includes("/proveedores/ver-comprobante-proveedores"))
      next();
    else {
      this.$store.state.dataItemOp = null;
      next();
    }
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: true,
      text: "",
      opaque: true,
    });
    this.movProvId = this.$route.params.mProvId ?? null;
    await this.setCombos();
    if (this.movProvId != null) {
      this.isEdit = true;
      if (
        !this.$route.params.tipoEdicion.canEditTotal &&
        this.$route.params.tipoEdicion.canEditImputacion
      ) {
        this.mensajeEditSoloImputacionNc = this.$route.params.tipoEdicion.mensaje;
        this.isEditTotalOp = false;
        this.isEditSoloImputacion = true;
      } else if (
        this.$route.params.tipoEdicion.canEditTotal &&
        this.$route.params.tipoEdicion.canEditImputacion
      ) {
        this.isEditTotalOp = true;
        this.isEditSoloImputacion = false;
      } else {
        this.mensajeEditSoloImputacionNc =
          this.$route.params.tipoEdicion.mensaje;
        this.isEditTotalOp = false;
        this.isEditSoloImputacion = false;
      }
      await this.setDatosOpEdit();
    } else {
      await this.setFechas();
      this.loadView = false;
      await this.setEmpresaProveedor(
        this.$route.params.empresaSelected,
        this.$route.params.provCod
      );
    }
    this.routeToGo =
      this.$route.params.routeToGoBack ?? "ComprobantesProveedor";

    this.$refs["btnComboEmpresas"]?.focus();
  },
  watch: {
    loadView(val) {
      if (!val) {
        this.$store.dispatch("user/changeAppLoading", {
          status: false,
          text: "",
          opaque: false,
        });
      }
    },
    comprobantes: {
     async handler(newVal) {
        if (!this.isEditSoloImputacion) {
          // Recalcular los totales cuando los comprobantes cambian
          if (newVal.length > 0) {
            await this.calcularTotales();
            this.pagoACuenta = false;
          } else {
            this.totalEnMonedaExtranjera = 0.0;
            this.totalEnMonedaPorDefecto = 0.0;
            this.totalNetoComprobantes = 0.0;
            this.totalIvaComprobantes = 0.0;
            this.totalPercepIvaComprobantes = 0.0;
            this.totalPercepcionIbComprobantes = 0.0;
            this.totalImpuestosInternosComprobantes = 0.0;
            this.totalOtrosConceptosComprobantes = 0.0;
            this.totalNetoComprobantesComp = 0.0;
            this.totalIvaComprobantesComp = 0.0;
            this.totalPercepIvaComprobantesComp = 0.0;
            this.totalPercepcionIbComprobantesComp = 0.0;
            this.totalImpuestosInternosComprobantesComp = 0.0;
            this.totalOtrosConceptosComprobantesComp = 0.0;
          }
        }
      },
      deep: false,
    },
    retencionesItems: {
      handler(newVal) {
        if (newVal.length > 0) this.calcularTotalesValores();
        else {
          this.totalValores = 0.0;
          this.totalValoresMonedaExtranjera = 0.0;
        }
      },
    },
  },
  computed: {
    disabledValores() {
      if (!this.enableValores && !this.isEdit) {
        if (
          (this.empresaSelected && this.empresaSelected.esAgenteRet) ||
          this.monedaOPSelected?.id !== this.monedaPorDefecto ||
          !this.empresaSelected
        )
          return true;
      } else return false;
    },
    showCotizacion() {
      let monedaOpDistinta = true;
      if (this.monedaOPSelected?.id == undefined)
        monedaOpDistinta =
          this.monedaOPSelected != null &&
          this.monedaOPSelected != this.monedaPorDefecto;
      else
        monedaOpDistinta =
          this.monedaOPSelected != null &&
          this.monedaOPSelected?.id != this.monedaPorDefecto;

      let monedaProvDistinta = this.monedaProvDistintaDefecto;
      return (
        this.monedaPorDefecto != null &&
        (monedaOpDistinta || monedaProvDistinta)
      );
    },
    monedaProvDistintaDefecto() {
      return (
        this.monedaProvSelected != null &&
        this.monedaProvSelected?.id !== this.monedaPorDefecto
      );
    },
    monedaExtranjeraNom() {
      let result = "";
      if (
        this.monedaOPSelected != null &&
        this.monedaOPSelected?.id != this.monedaPorDefecto
      ) {
        result = this.monedaOPSelected?.value;
      } else if (this.monedaProvDistintaDefecto) {
        result = this.monedaProvSelected.value;
      }
      return result;
    },
    filteredHeadersComp() {
      let monedaProvDistinta = this.monedaProvDistintaDefecto;
      return this.headersComprobantes.filter((header) => {
        //usamos saldoComp solo cuando la moneda del proveedor es distinta de la porDefecto.
        if (monedaProvDistinta && header.value === "saldo") return false;
        if (
          !monedaProvDistinta &&
          (header.value === "diferenciaCambio" || header.value === "saldoComp")
        )
          return false;

        return true;
      });
    },
    filteredHeadersRet() {
      let monedaProvDistinta =
        this.monedaProvDistintaDefecto || this.showCotizacion;
      return this.headersRetenciones.filter((header) => {
        if (monedaProvDistinta && header.value == "importeComp") {
          header.text = `Importe ${this.monedaExtranjeraNom?.toLowerCase()}`;
        }
        if (!monedaProvDistinta && header.value === "importeComp") {
          return false;
        }
        return true;
      });
    },
    sumaValoresRetenciones() {
      let suma =
        this.retencionesItems.length > 0
          ? this.retencionesItems.reduce(
              (acc, item) => parseFloat((acc + item.imp).toFixed(2)),
              0
            )
          : 0;
      return suma;
    },
    sumaCancelaComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) => parseFloat((acc + item.cancela).toFixed(2)),
              0
            )
          : 0;
      return suma;
    },
    sumaCancelaCompComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) => parseFloat((acc + item.cancelaComp).toFixed(2)),
              0
            )
          : 0;
      return suma;
    },
    sumaNetoComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (
                    acc +
                    item.netoProporcional * item.cotizacionComprobante
                  ).toFixed(2)
                ),
              0
            )
          : 0;
      let sumaComp =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat((acc + item.netoProporcional).toFixed(2)),
              0
            )
          : 0;
      return [suma, sumaComp];
    },
    sumaIvaComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (
                    acc +
                    item.ivaProporcional * item.cotizacionComprobante
                  ).toFixed(2)
                ),
              0
            )
          : 0;
      let sumaComp =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat((acc + item.ivaProporcional).toFixed(2)),
              0
            )
          : 0;
      return [suma, sumaComp];
    },
    sumaPercepIvaComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (
                    acc +
                    item.percepcionIvaProporcional * item.cotizacionComprobante
                  ).toFixed(2)
                ),
              0
            )
          : 0;
      let sumaComp =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat((acc + item.percepcionIvaProporcional).toFixed(2)),
              0
            )
          : 0;
      return [suma, sumaComp];
    },
    sumaPercepIbComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (
                    acc +
                    item.percepcionIBProporcional * item.cotizacionComprobante
                  ).toFixed(2)
                ),
              0
            )
          : 0;
      let sumaComp =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat((acc + item.percepcionIBProporcional).toFixed(2)),
              0
            )
          : 0;
      return [suma, sumaComp];
    },
    sumaImpInternosComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (
                    acc +
                    item.impuestosInternosProporcional *
                      item.cotizacionComprobante
                  ).toFixed(2)
                ),
              0
            )
          : 0;
      let sumaComp =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (acc + item.impuestosInternosProporcional).toFixed(2)
                ),
              0
            )
          : 0;
      return [suma, sumaComp];
    },
    sumaOtrosConceptosComprobantes() {
      //si la factura es moneda dolar vienen los importes en dolares en otrosConceptosProporcional
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat(
                  (
                    acc +
                    item.otrosConceptosProporcional * item.cotizacionComprobante
                  ).toFixed(2)
                ),
              0
            )
          : 0;
      let sumaComp =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) =>
                parseFloat((acc + item.otrosConceptosProporcional).toFixed(2)),
              0
            )
          : 0;
      return [suma, sumaComp];
    },
  },
  methods: {
    ...mapActions({
      getCondicionesIIBB: "afiliaciones/getCondicionesIIBB",
      getConceptosIIBB: "afiliaciones/getConceptosIIBB",
      getCondicionesGanancias: "taxonomy/getCondicionesGanancias",
      getConceptosGanancias: "taxonomy/getConceptosGanancias",
      getConceptosCondicionesProveedor:
        "proveedores/getConceptosCondicionesProveedor",
      getTiposComprobanteCargaOrdenesDePago:
        "proveedores/getTiposComprobanteCargaOrdenesDePago",
      getLetraTipoCompProveedor: "proveedores/getLetraTipoCompProveedor",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      getParametroGralCargaFacturaEnCero:
        "taxonomy/getParametroGralCargaFacturaEnCero",
      getEntidadesFacturantes: "proveedores/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      calcularRetenciones: "proveedores/calcularRetenciones",
      getParametroGralFechaContableOp:
        "proveedores/getParametroGralFechaContableOp",
      grabarOp: "proveedores/grabarOpNc",
      grabarRelacionNotaCreditoOp: "proveedores/grabarRelacionNotaCreditoOp",
      getOpDetalleCompletoProv: "proveedores/getOpDetalleCompletoProv",
      getSaldosPorConcepto: "proveedores/getSaldosPorConcepto",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      setAlert: "user/setAlert",
    }),
    appLoading() {
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false,
      });
    },
    async setDatosOpEdit() {
      let res = null;
      try {
        const response = await this.getOpDetalleCompletoProv(this.movProvId);
        let monedaIdComp = 1;
        if (response) {
          res = response;
          monedaIdComp = response.monedaIdComp;
          await this.setEmpresaProveedor(response.entFacId, response.provCod);
          this.tipoComprobanteSelected = this.tiposComprobantes.find(
            (x) => x.id == response.tipoCompId
          );
          await this.setDatosTipoComprobante();
          this.letraComprobante = response.letra.toString();
          this.sucursalComprobante = response.sucursal.toString();
          this.numeroComprobante = response.numero.toString();
          this.conceptoIBSelected = this.conceptosIB.find(
            (x) => x?.id == response.concIBId
          );
          this.cotizacion = response.cotizacion;
          //fecha cont
          this.fechaContable = helpers.parseDateToIso(response.fechaContString);
          this.fechaContableSelected = response.fechaContString;
          // fecha
          this.fecha = helpers.parseDateToIso(response.fechaString);
          this.fechaSelected = response.fechaString;
          this.conceptoPago = response.obs;
          this.retencionesItems = response.listaValores;
          setTimeout(async () => {
            this.totalEnMonedaPorDefecto = response.total;
            if (this.monedaProvDistintaDefecto)
              this.totalEnMonedaExtranjera = response.totalComp;
            //validamos la cotizacion con la del comprobante para ver cual se debe usar//solo cuando se usa otra moneda

            if (this.monedaProvDistintaDefecto || this.showCotizacion)
              response.listaComprobante.map((x) => {
                if (x.cotizacionComprobante < this.cotizacion)
                  x.cancela =
                    x.cancelaComp * x.cotizacionComprobante ?? this.cotizacion;
                else x.cancela = x.cancelaComp * this.cotizacion;
              });
          }, 1000);
        }
        this.comprobantes = response.listaComprobante;
        this.monedaOPSelected = this.monedas.find((x) => x.id == monedaIdComp);
      } catch {}
      await this.setTotales(res);
      this.loadView = false;
    },
    async setTotales(response) {
      setTimeout(() => {
        this.totalNetoComprobantes = response.neto;
        this.totalPercepIvaComprobantes = response.percepcionIva;
        this.totalPercepcionIbComprobantes = response.percepcionIb;
        this.totalPercepcionIbComprobantesComp = response.percepcionIbComp;
        this.totalImpuestosInternosComprobantes = response.impuestosInternos;
        this.totalOtrosConceptosComprobantes = response.otrosConceptos;
        this.totalIvaComprobantes = response.iva;
        this.totalNetoComprobantesComp = response.netoComp;
        this.totalPercepIvaComprobantesComp = response.percepcionIvaComp;
        this.totalImpuestosInternosComprobantesComp = response.impuestosInternosComp;
        this.totalOtrosConceptosComprobantesComp = response.otrosConceptosComp;
        this.totalIvaComprobantesComp = response.ivaComp;
      }, 3000);
    },
    async calcularTotales() {
      if (!this.monedaProvDistintaDefecto) {
        //total moneda por defecto
        this.totalEnMonedaPorDefecto = parseFloat(this.sumaCancelaComprobantes);
        //total moneda extranjera
        if (this.showCotizacion) {
          let resultMonedaExtranjera = parseFloat(
            this.sumaCancelaCompComprobantes
          );
          if (
            isNaN(this.sumaCancelaCompComprobantes) ||
            this.sumaCancelaCompComprobantes === 0
          ) {
            resultMonedaExtranjera =
              this.sumaCancelaComprobantes / this.cotizacion;
          }
          this.totalEnMonedaExtranjera = resultMonedaExtranjera;
        } else this.totalEnMonedaExtranjera = 0;
      } else if (this.monedaProvDistintaDefecto) {
        //total moneda extranjera
        this.totalEnMonedaExtranjera = parseFloat(
          this.sumaCancelaCompComprobantes
        );
        //total moneda por defecto
        this.totalEnMonedaPorDefecto = parseFloat(
          this.sumaCancelaCompComprobantes * this.cotizacion
        );
        this.comprobantes.forEach((x) => {
          if (x.cotizacionComprobante < this.cotizacion)
            x.cancela =
              x.cancelaComp * x.cotizacionComprobante ?? this.cotizacion;
          else x.cancela = x.cancelaComp * this.cotizacion;
        });
      }
      //calculamos los totales
      await this.calcularTotalesProporcional();
      await this.setTotalesComputed();
    },
    async calcularTotalesProporcional() {
      this.comprobantes.forEach(async (comprobante) => {
        const saldos = await this.getSaldosPorConcepto(comprobante.mProvId);
        const {
          total,
          neto,
          cancela,
          cancelaComp,
          iva,
          percepcionIva,
          percepcionIB,
          impuestosInternos,
          otrosConceptos,
        } = comprobante;
        const factor = parseFloat((this.showCotizacion ? cancelaComp : cancela) / total);
        if (saldos == null) {
          if (total > 0 && cancela > 0) {
            // Guardamos el neto proporcional en el comprobante
            comprobante.ivaProporcional = iva * factor;
            comprobante.percepcionIvaProporcional = percepcionIva * factor;
            comprobante.percepcionIBProporcional = percepcionIB * factor;
            comprobante.impuestosInternosProporcional = impuestosInternos * factor;
            comprobante.otrosConceptosProporcional = otrosConceptos * factor;
            comprobante.netoProporcional = neto * factor;
          } // Si no hay un total o cancela válido, establecemos netoProporcional en 0
          else comprobante.netoProporcional = 0;
        } else {
          if (total > 0 && cancela > 0) {
            // aca el comprobante tiene otro saldo asique lo multiplicamos por el factor
            comprobante.ivaProporcional = saldos.saldoIva !== 0 ? saldos.saldoIva : iva * factor;
            comprobante.percepcionIvaProporcional = saldos.saldoPercepcionIva !== 0 ? saldos.saldoPercepcionIva : percepcionIva * factor;
            comprobante.percepcionIBProporcional = saldos.saldoPercepcionIB !== 0 ? saldos.saldoPercepcionIB : percepcionIB * factor;
            comprobante.impuestosInternosProporcional = saldos.saldoImpuestosInternos !== 0 ? saldos.saldoImpuestosInternos : impuestosInternos * factor;
            comprobante.otrosConceptosProporcional = saldos.saldoOtrosConceptos !== 0 ? saldos.saldoOtrosConceptos : otrosConceptos * factor;
            comprobante.netoProporcional = saldos.saldoNeto !== 0 ? saldos.saldoNeto : neto * factor;
          } else comprobante.netoProporcional = 0;
        }
      });
    },
    async setTotalesComputed() {
      setTimeout(async () => {
        if (this.showCotizacion) {
          this.totalOtrosConceptosComprobantes = await this.sumaOtrosConceptosComprobantes[0];
          this.totalOtrosConceptosComprobantesComp = await this.sumaOtrosConceptosComprobantes[1];

          this.totalImpuestosInternosComprobantes = await this.sumaImpInternosComprobantes[0];
          this.totalImpuestosInternosComprobantesComp = await this.sumaImpInternosComprobantes[1];

          this.totalPercepIvaComprobantes = await this.sumaPercepIvaComprobantes[0];
          this.totalPercepIvaComprobantesComp = await this.sumaPercepIvaComprobantes[1];

          this.totalIvaComprobantes = await this.sumaIvaComprobantes[0];
          this.totalIvaComprobantesComp = await this.sumaIvaComprobantes[1];

          this.totalPercepcionIbComprobantes = await this.sumaPercepIbComprobantes[0];
          this.totalPercepcionIbComprobantesComp = await this.sumaPercepIbComprobantes[1];

          this.totalNetoComprobantes = await this.sumaNetoComprobantes[0];
          this.totalNetoComprobantesComp = await this.sumaNetoComprobantes[1];
        } else {
          this.totalIvaComprobantes = await this.sumaIvaComprobantes[1];
          this.totalPercepIvaComprobantes = await this.sumaPercepIvaComprobantes[1];
          this.totalPercepcionIbComprobantes = await this.sumaPercepIbComprobantes[1];
          this.totalImpuestosInternosComprobantes = await this.sumaImpInternosComprobantes[1];
          this.totalOtrosConceptosComprobantes = await this.sumaOtrosConceptosComprobantes[1];
          this.totalNetoComprobantes = this.sumaNetoComprobantes[1];     
        }
      }, 2000);
    },
    calcularTotalesValores() {
      //total valores moneda extranjera
      if (this.showCotizacion)
        this.totalValoresMonedaExtranjera = parseFloat((this.sumaValoresRetenciones / this.cotizacion).toFixed(2));

      //total valores moneda por defecto
      this.totalValores = this.sumaValoresRetenciones;
      this.calcularImporteRetSegunCotizacion();
    },
    calcularTotalEnPeso() {
      this.totalEnMonedaPorDefecto = parseFloat((this.totalEnMonedaExtranjera * this.cotizacion).toFixed(2));
    },
    calcularTotalEnDolar() {
      if (this.showCotizacion)
        this.totalEnMonedaExtranjera = parseFloat((this.totalEnMonedaPorDefecto / this.cotizacion).toFixed(2));
    },
    calcularImporteRetSegunCotizacion() {
      this.retencionesItems.forEach((x) => {
        if (x.imp !== 0) {
          x.importeComp = parseFloat((x.imp / this.cotizacion).toFixed(2));
          x.importeComp = x.importeComp;
        } else x.importeComp = 0;
      });

      if (this.showCotizacion && this.totalValoresMonedaExtranjera == this.totalEnMonedaExtranjera)
          this.totalValores = this.totalEnMonedaPorDefecto; //le asigno directamente el valor para que no de distinto
    },
    distribuirComprobantes() {
      let saldoDisponible = 0;
      saldoDisponible = this.totalEnMonedaPorDefecto;

      let saldoDisponibleComp = 0;
      saldoDisponibleComp = this.totalEnMonedaExtranjera;

      this.comprobantes.forEach((comprobante) => {
        //primer caso: cancelo en base a pesos
        if (!this.monedaProvDistintaDefecto) {
          // Obtener el saldo pendiente del comprobante
          let saldoComprobante = comprobante.saldo;
          // Si el saldo disponible es mayor o igual que el saldo del comprobante,
          // cancelo el comprobante completamente
          if (saldoDisponible >= saldoComprobante) {
            comprobante.cancela = saldoComprobante; // Cancelo el saldo completo
            saldoDisponible -= saldoComprobante; // Resto el saldo disponible
          } else {
            // Si no hay suficiente saldo para cancelar todo el comprobante, cancelo solo la parte que queda del saldo disponible
            comprobante.cancela = saldoDisponible; // Cancelo lo que queda del saldo disponible
            saldoDisponible = 0; // Ya no queda saldo disponible
          }

          //cuando es peso-peso dejamos el cancelaComp en 0
          if (this.monedaOPSelected.id == this.monedaProvSelected.id)
            comprobante.cancelaComp = 0;
          else comprobante.cancelaComp = comprobante.cancela / this.cotizacion;

          //segundo caso: los mismo pero cancelo en base a dolares
        } else if (this.monedaProvDistintaDefecto) {
          let saldoCompComprobante = comprobante.saldoComp;

          if (saldoDisponibleComp >= saldoCompComprobante) {
            comprobante.cancelaComp = saldoCompComprobante;
            saldoDisponibleComp -= saldoCompComprobante;
          } else {
            comprobante.cancelaComp = saldoDisponibleComp;
            saldoDisponibleComp = 0;
          }

          //luego de distribuir calculamos el cancela en base al cancelaComp y la cotizacion
          if (comprobante.cancelaComp !== 0)
            comprobante.cancela = comprobante.cancelaComp * this.cotizacion;
          else comprobante.cancela = 0;
        }
      });
      this.calcularDiferenciaCambio(this.cotizacion);
    },
    async setEmpresaProveedor(entFacId, provCod) {
      if (entFacId != null) {
        this.empresaSelected = this.empresas.find((x) => x.id == entFacId);
        this.getTiposComprobantes();
        if (!this.isEdit) this.getLetraComprobanteByProvEntFacId();
      }
      if (provCod != null) {
        this.provCod = provCod;
        await this.buscarProveedor();
      }
    },
    async getLetraComprobanteByProvEntFacId() {
      if (this.entFacId != null && this.provId != null && !this.isEdit) {
        const letraResponse = await this.getLetraTipoCompProveedor({
          entFacId: this.entFacId,
          provId: this.provId,
        });
        if (letraResponse != null && letraResponse.length > 0)
          this.letraComprobante = letraResponse;
      }
    },
    calcularDiferenciaCambio(cotizacionParam) {
      if (!this.monedaProvDistintaDefecto) {
        this.comprobantes = this.comprobantes.map((comprobante) => {
          // Calcula la diferencia de cambio
          const diferenciaCambio =
            comprobante.cancela * cotizacionParam -
            comprobante.cancela * comprobante.cotizacionComprobante;

          // Retorna un nuevo objeto con el valor actualizado
          return {
            ...comprobante,
            diferenciaCambio: diferenciaCambio,
          };
        });
      } else if (this.monedaProvDistintaDefecto) {
        this.comprobantes = this.comprobantes.map((comprobante) => {
          // Calcula la diferencia de cambio pero en base al cancelaComp
          const diferenciaCambio =
            comprobante.cancelaComp * cotizacionParam -
            comprobante.cancelaComp * comprobante.cotizacionComprobante;

          return {
            ...comprobante,
            diferenciaCambio: diferenciaCambio,
          };
        });
      }
    },
    async setFechas() {
      let today = new Date();
      let año = today.getFullYear();
      let mes = (today.getMonth() + 1).toString().padStart(2, "0");
      let dia = today.getDate().toString().padStart(2, "0");

      let date = `${año}-${mes}-${dia}`;
      let dateFormated = this.helpers.formatDate(date);

      this.fecha = date;
      this.fechaSelected = dateFormated;
      const parametroFechaContableOp =
        await this.getParametroGralFechaContableOp();
      //si hay fecha en el param gral la seteamos
      if (parametroFechaContableOp !== "") {
        let dia = parametroFechaContableOp.toString().substring(0, 2);
        let mes = parametroFechaContableOp.toString().substring(3, 5);
        let año = parametroFechaContableOp.toString().substring(6, 10);

        this.fechaContable = `${año}-${mes}-${dia}`;
        this.fechaContableSelected = parametroFechaContableOp;
        this.disableFecContable = true;
      } else {
        this.fechaContable = date;
        this.fechaContableSelected = dateFormated;
      }
    },
    async buscarProveedor() {
      if (
        this.provCod !== null &&
        this.provCod !== undefined &&
        this.provCod !== ""
      ) {
        try {
          this.proveedoresLoading = true;
          this.proveedores = await this.getProveedoresByCod(this.provCod);
          if (this.proveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Proveedor inexistente",
            });
            this.provId = null;
            this.proveedorSelected = null;
            this.provNom = null;
            this.proveedoresLoading = false;
            return;
          }
          this.proveedoresLoading = false;
          this.proveedorSelected = this.proveedores[0];
          this.provId = this.proveedorSelected.id;
          this.provNom = this.proveedorSelected.value1.toUpperCase();
          this.cuit = this.proveedorSelected.cuit;
          this.comprobantes = [];
          this.retencionesItems = [];
          await this.getMonedaProveedor();
          await this.setCondicionesConceptosProveedor();
          await this.getLetraComprobanteByProvEntFacId();
        } catch (error) {
          this.proveedoresLoading = false;
          this.proveedorSelected = null;
          this.provId = null;
        }
      } else {
        this.proveedorSelected = null;
        this.provId = null;
        this.provNom = null;
        this.condicionGananciaSelected = null;
        this.condicionIBSelected = null;
        this.monedaProvSelected = null;
        this.modalidadPago = null;
        this.tipoValor = null;
        this.comprobantes = [];
        this.cotizacion = 1;
        this.retencionesItems = [];
      }
    },
    async findProveedor(proveedor) {
      this.proveedorSelected = proveedor;
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
      this.proveedorSelected.value1 = this.provNom.toUpperCase();
      this.proveedorSelected.value2 = this.provCod;
      this.proveedorSelected.id = this.provId;
      this.comprobantes = [];
      this.retencionesItems = [];
      await this.getMonedaProveedor();
      await this.setCondicionesConceptosProveedor();
      await this.getLetraComprobanteByProvEntFacId();
    },
    async getMonedaProveedor() {
      if (this.provId != null) {
        const response = await this.getMonedaDeProveedor(this.provId);
        if (response != null) {
          this.monedaProvSelected = this.monedas.find((x) => x.id == response.id);
          if (!this.isEdit) this.getCotizacion(this.monedaProvSelected?.id);
        }
      }
    },
    setRetenciones() {
      if (this.comprobantes.length === 0) {
        //abrimos modal para proponer un neto por pantalla
        this.openInputNetoDialog = true;
      } else {
        if (this.retencionesItems.length > 0) this.openConfirmDialog = true;
        else this.confirmSetRetenciones();
      }
    },
    async confirmSetRetenciones() {
      this.loadingBtnReteneciones = true;
      const data = {
        mProvId: 0, //mProvId solo editar
        entFacId: this.empresaSelected.id,
        provId: this.provId,
        fecha: this.fecha,
        neto: parseFloat(this.totalNetoComprobantes),
        total: parseFloat(this.totalEnMonedaPorDefecto),
        condGanId: this.condicionGananciaSelected?.id,
        concGanId: this.conceptoGananciaSelected?.id,
        condIIBBId: this.condicionIBSelected?.id ?? 0,
        concIIBBId: this.conceptoIBSelected?.id ?? 0,
        listaCompCancelado: this.comprobantes,
      };
      try {
        const response = await this.calcularRetenciones(data);
        if (response && response.length > 0) {
          this.retencionesItems = response.filter(x => x.imp !== 0);
          this.retencionesItems.map((item) => (item.fechaString = item.fecha));
        }
        this.enableValores = true;
      } catch {
      } finally {
        this.loadingBtnReteneciones = false;
      }
    },
    async setDatosTipoComprobante() {
      if (this.tipoComprobanteSelected != null) {
        const response = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteSelected.id
        );
        let datosTipoComprobante = response;
        this.tCompEditaFecha = datosTipoComprobante.tCompEditaFecha;
        this.tCompSigno = datosTipoComprobante.tCompSigno;
        this.tieneNumeradorAutomatico = datosTipoComprobante.numAuto;
        if (datosTipoComprobante.numAutoLetra.trim().length > 0)
          this.letraComprobante = datosTipoComprobante.numAutoLetra.toString();

        if (datosTipoComprobante.numAutoSuc)
          this.sucursalComprobante = datosTipoComprobante.numAutoSuc.toString();

        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected.id,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
        if (this.tieneNumeradorAutomatico == false) {
          this.setAlert({
            type: "warning",
            message: `No está configurado el numerador autómatico. \n Tipo de comprobante: '${datosTipoComprobante.tCompNombre}' \nLetra: '${datosTipoComprobante.numAutoLetra}' \nSucursal: '${datosTipoComprobante.numAutoSuc}'`,
          });
        }
      } else {
        this.letraComprobante = "";
        this.sucursalComprobante = "";
        this.numeroComprobante = "";
        this.tieneNumeradorAutomatico = false;
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (!this.isEdit && letra && tCompId && sucursal) {
        this.numeroComprobante = "0";

        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico =
            await this.getTipoCompTieneNumAutomatico({
              tCompId: tCompId?.id ?? tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra,
            });
        } else this.tieneNumeradorAutomatico = false;
      }
    },
    async setCondicionesConceptosProveedor() {
      if (this.provId != null) {
        const response = await this.getConceptosCondicionesProveedor(
          this.provId
        );
        this.condicionGananciaSelected = this.condicionesGanancias.find((x) => x.id == response.condGanId);
        this.conceptoGananciaSelected = this.conceptosGanancias.find((x) => x.id == response.concGanId);
        this.condicionIBSelected = this.condicionesIB.find((x) => x.id == response.condIbId);
        this.conceptoIBSelected = this.conceptosGanancias.find((x) => x.id == response.concIbId);
        this.modalidadPago = response.modalidadPago;
        this.tipoValor = response.tipoValor;
        this.tIvaId = response.iva.id;
        //if (this.tipoValor != null)
        //this.monedaOPSelected = this.monedas.find((x) => x.id == response.monedaOp);
        //else ----> se cambio esto para que se setee siempre la moneda por defecto
        this.monedaOPSelected = this.monedas.find(
          (x) => x.id == this.monedaPorDefecto
        );
      }
    },
    async getTiposComprobantes() {
      if (this.empresaSelected != null) {
        this.tiposComprobantesLoading = true;
        this.tiposComprobantes = await this.getTiposComprobanteCargaOrdenesDePago(this.empresaSelected.id);
        this.tiposComprobantesLoading = false;
      }
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    toggleModalValoresCartera() {
      this.buscarValoresCarteraModal = !this.buscarValoresCarteraModal;
    },
    toggleModalComprobantesCancelar() {
      this.buscarComprobantesCancelar = !this.buscarComprobantesCancelar;
    },
    setComprobantesCancelar(comprobantesACancelar) {
      if (comprobantesACancelar)
        this.comprobantes = this.comprobantes.concat(comprobantesACancelar);

      if (!this.showCotizacion)
        this.comprobantes.map((item) => (item.diferenciaCambio = 0));
    },
    setValoresCartera(itemsSelected) {
      if (itemsSelected) {
        this.retencionesItems = this.retencionesItems.concat(itemsSelected);
        this.retencionesItems.map((item) => (item.fechaString = item.fecha));
      }
    },
    volver() {
      this.$router.push({
        name: this.routeToGo,
      });
    },
    validarTotalesIguales() {
      if (this.monedaProvDistintaDefecto) {
        if (this.totalEnMonedaExtranjera !== this.totalValoresMonedaExtranjera) {
          this.setAlert({
            type: "error",
            message: `El total cancelado es distinto al total de los valores. `,
          });
          return false;
        }
      } else {
        if (this.totalEnMonedaPorDefecto !== this.totalValores) {
          this.setAlert({
            type: "error",
            message: `El total cancelado es distinto al total de los valores. `,
          });
          return false;
        }
      }
      if (this.isEdit && this.sumaCancelaComprobantes > this.totalEnMonedaPorDefecto) {
        this.setAlert({
            type: "error",
            message: `El total cancelado no puede ser mayor al total del comprobante. `,
          });
          return false;
      }
      return true;
    },
    async saveOrdenPago() {
      if (!this.validarTotalesIguales()) return;
      this.loadingSaveBtn = true;
      const dataOp = {
        sesion: 0,
        mProv_id: this.isEdit ? this.movProvId : 0,
        fecha: this.fecha,
        fechaVenc: null,
        fecContable: this.fechaContable,
        provId: this.provId,
        nombre: this.proveedorSelected.value1,
        cuit: this.proveedorSelected.cuit,
        direccion: this.proveedorSelected.domicilio,
        tCompId: this.tipoComprobanteSelected.id,
        letra: this.letraComprobante,
        suc: this.sucursalComprobante.toString(),
        numero: parseInt(this.numeroComprobante),
        tIvaId: this.tIvaId,
        neto: this.totalNetoComprobantes,
        IVA: this.totalIvaComprobantes,
        percepcionIva: this.totalPercepIvaComprobantes,
        percepcionIb: this.totalPercepcionIbComprobantes,
        impuestosInternos: this.totalImpuestosInternosComprobantes,
        otrosConceptos: this.totalOtrosConceptosComprobantes,
        total: this.totalEnMonedaPorDefecto,
        monedaId: this.monedaPorDefecto,
        entFacId: this.empresaSelected.id,
        totalComp:
          this.monedaOPSelected.id !== this.monedaPorDefecto ||
          this.monedaProvDistintaDefecto
            ? this.totalEnMonedaExtranjera
            : 0,
        obs: this.conceptoPago,
        signo: this.tCompSigno,
        condGanId: this.condicionGananciaSelected?.id,
        concGanId: this.conceptoGananciaSelected?.id,
        toId: null,
        concIbId: this.conceptoIBSelected?.id,
        condIbId: this.condicionIBSelected?.id,
        periodo: null,
        provIdCdo: null,
        caeCaiProv: null,
        caeCaiProvFecVen: null,
        tEmision: null,
        netoComp:
          this.monedaOPSelected.id !== this.monedaPorDefecto ||
          this.monedaProvDistintaDefecto
            ? this.totalNetoComprobantesComp
            : null,
        IVAComp: this.showCotizacion ? this.totalIvaComprobantesComp : null,
        percepcionIvaComp: this.showCotizacion ? this.totalPercepIvaComprobantesComp : null,
        percepcionIbComp: this.showCotizacion ? this.totalPercepcionIbComprobantesComp : null,
        impuestosInternosComp: this.showCotizacion ? this.totalImpuestosInternosComprobantesComp : null,
        otrosConceptosComp: this.showCotizacion ? this.totalOtrosConceptosComprobantesComp : null,
        tipoComprobanteDif: "Op",
        tcompTieneNumeradorAutomatico: this.tieneNumeradorAutomatico,
        monedaIdComp:
          this.monedaOPSelected.id == this.monedaPorDefecto &&
          !this.monedaProvDistintaDefecto
            ? null
            : this.monedaOPSelected.id,
        cotizacion: this.cotizacion,
        listaValores: this.retencionesItems.map((item) => {
          if (item.fecha !== null)
            item.fecha = helpers.parseDateToIso(item.fechaString);
          return item;
        }),
        editaImputacion: this.isEditSoloImputacion,
        listaComprobante: this.comprobantes,
        listaPercepcionIB: [],
        listaAlicuota: [],
        listaImputContable: [],
        listaCentrosCostos: [],
      };
      try {
        if (this.isEdit && !this.isEditTotalOp) {
          const response = await this.grabarRelacionNotaCreditoOp(dataOp);
          if (response && response.idError == 0) {
            this.setAlert({
              type: "success",
              message: `Orden de pago grabada con éxito. ${response.mensaje}`,
            });
            this.volver();
          } else {
            this.setAlert({
              type: "error",
              message: `Error al grabar orden de pago: ${response.mensaje}`,
            });
          }
        } else {
          const response = await this.grabarOp(dataOp);
          if (response && response.idError == 0) {
            this.setAlert({
              type: "success",
              message: `Orden de pago grabada con éxito. ${response.mensaje}`,
            });
            this.volver();
          } else {
            this.setAlert({
              type: "error",
              message: `Error al grabar orden de pago: ${response.mensaje}`,
            });
          }
        }
      } catch {
      } finally {
        this.loadingSaveBtn = false;
      }
    },
    async setCombos() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      this.empresas = await this.getEntidadesFacturantes();
      if (this.empresas.length == 1) {
        this.empresaSelected = {
          cuit: this.empresas[0].cuit,
          esAgenteRet: this.empresas[0].esAgenteRet,
          esMultiJurisdiccion: this.empresas[0].esMultiJurisdiccion,
          id: this.empresas[0].id,
          value: this.empresas[0].value,
        };
        this.getTiposComprobantes();
      }
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      let monedaPorDefecto = this.monedas.find((x) => x.bool == true);
      this.monedaOPSelected = monedaPorDefecto;
      this.monedaPorDefecto = monedaPorDefecto.id;
      this.monedaPorDefectoNom = monedaPorDefecto.value;
      this.siglaMonedaPorDefecto = this.parametroGeneralMultimoneda
        ? monedaPorDefecto.value2
        : "";
      this.conceptosIB = await this.getConceptosIIBB();
      this.condicionesIB = await this.getCondicionesIIBB();
      this.conceptosGanancias = await this.getConceptosGanancias();
      this.condicionesGanancias = await this.getCondicionesGanancias();
    },
    async getCotizacion(monedaId) {
      if (monedaId != null) {
        this.cotizacionLoading = true;
        const payload = {
          monedaId:
            this.monedaProvDistintaDefecto && monedaId == 1 //usar la que sea dolar
              ? this.monedaProvSelected.id
              : monedaId,
          fechaComprobante: this.fecha,
        };
        try {
          const response = await this.getCotizacionMoneda(payload);
          if (response) this.cotizacion = response;
          else this.cotizacion = null;
        } catch (error) {
        } finally {
          this.cotizacionLoading = false;
        }
      }
    },
    exportComprobantes() {
      let result = [];
      if (this.monedaProvDistintaDefecto) {
        this.comprobantes?.forEach((x) =>
          result.push({
            ["Comprobante"]: x.nroComp,
            ["Fecha"]: x.fechaComprobanteFormatted,
            ["Vencimiento"]: x.fechaVencimientoFormatted,
            ["Total"]: x.totalFormatted,
            ["Cancelado"]: x.canceladoFormatted,
            ["Saldo"]: x.saldoFormatted,
            ["Cancela"]: helpers.floatToMoneyString(x.cancela, "", true),
            [`Cancela ${this.monedaExtranjeraNom}`]: helpers.floatToMoneyString(
              x.cancelaComp,
              "",
              false
            ),
            ["Cotización"]: x.cotizacionComprobanteFormatted,
            ["Diferencia de cambio"]: helpers.floatToMoneyString(
              x.diferenciaCambio,
              "",
              false
            ),
            ["Formula diferencia de cambio"]: `(${x.cancelaComp} * ${this.cotizacion}) - (${x.cancelaComp} * ${x.cotizacionComprobante})`,
          })
        );
      } else {
        this.comprobantes?.forEach((x) =>
          result.push({
            ["Comprobante"]: x.nroComp,
            ["Fecha"]: x.fechaComprobanteFormatted,
            ["Vencimiento"]: x.fechaVencimientoFormatted,
            ["Total"]: x.totalFormatted,
            ["Cancelado"]: x.canceladoFormatted,
            ["Saldo"]: x.saldoFormatted,
            ["Cancela"]: helpers.floatToMoneyString(x.cancela, "", true),
          })
        );
      }
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Comprobantes a cancelar");
    },
    goToVerComprobante(item) {
      this.openSeeComp = true;
      this.paramsToSeeComp = {
        movProvId: item.mProvId,
        monedaToDetalle: this.monedaProvSelected,
        fechaCotizaToDetalle: this.fecha,
      };
    },
    toggleNuevoTipoValor() {
      if (this.monedaPorDefecto != this.monedaOPSelected.id) {
        this.importeSugerido = this.totalEnMonedaExtranjera - this.totalValoresMonedaExtranjera;
      } else
        this.importeSugerido = this.totalEnMonedaPorDefecto - this.totalValores;

      this.detalleTipoValorModal = !this.detalleTipoValorModal;
    },
    addTipoDeValor(detalle) {
      this.retencionesItems.push(detalle);
      this.retencionesItems.map((item) => (item.fechaString = item.fecha));
    },
    async onChangeCancelaComp(item) {
      item.cancela = item.cancelaComp * item.cotizacionComprobante;
      if (!this.isEditSoloImputacion) {
        await this.calcularTotalesProporcional();
        await this.setTotalesComputed();
      }
      this.calcularDiferenciaCambio(this.cotizacion);
    },
    async onChangeCancela(item) {
      if (this.showCotizacion) item.cancelaComp = item.cancela / this.cotizacion;
      else item.cancelaComp = 0;
      if (!this.isEditSoloImputacion) {
        await this.calcularTotalesProporcional();
        await this.setTotalesComputed();
      }
      this.calcularDiferenciaCambio(this.cotizacion);
    },
  },
};
</script>

<style scoped>
.v-data-table {
  margin-bottom: 0;
}
.invalidConfigText {
  color: red;
}
.text-right {
  text-align: right;
}
.right-input >>> input {
  text-align: right;
}
.custom-data-table >>> .v-data-table-header th,
.custom-data-table >>> .v-data-table__divider,
.custom-data-table >>> .v-data-table__wrapper td,
.custom-data-table >>> .v-data-table__mobile-table-row {
  padding: 4px 8px;
  margin: 0;
}
</style>
